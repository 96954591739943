




































































































import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Component, Vue, Watch } from "vue-property-decorator";
import { InformationAdditionnelle } from "@/api/models/informationAdditionnelle/informationAdditionnelle";
import { PaginatedList } from "@/api/models/common/paginatedList";
import AppTimeline from "@/@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@/@core/components/app-timeline/AppTimelineItem.vue";
import { TypeEntiteEnum } from "@/api/models/enums/typeEntiteEnum";
import DetailsInformationAdditionnelle from "../informationAdditionnelles/DetailsModal.vue";
import { BAlert, BPagination, BCol, BButtonGroup } from "bootstrap-vue";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    DetailsInformationAdditionnelle,
    BAlert, 
    BPagination,
    BCol,
    BButtonGroup
  },
  name: "information-additionnelle",
})
export default class CreateInformationAdditionnelleModal extends Vue {
  perPage = 10;
  currentPage = 1;
  selectedId: string = "";
  listInformationsAdditionnelles: PaginatedList<InformationAdditionnelle> =
    new PaginatedList<InformationAdditionnelle>();

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.listInformationsAdditionnelles.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.listInformationsAdditionnelles.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  @Watch("pertinentOnly")
  async paginationChanged() {
    await this.loadInformationsAdditionnelles({
      TypeEntite: null,
      TypeEntiteId: null,
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  async created() {
    await this.loadInformationsAdditionnelles({
      TypeEntite: null,
      TypeEntiteId: null,
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  openDetailsModal(id: string) {
    this.selectedId = id;
    // console.log(this.$refs.detailsInformationAdditionnelle);
    // (
    //   this.$refs.DetailsInformationAdditionnelle as any
    // ).informationAdditionnelleId = id;
    // (this.$refs.DetailsInformationAdditionnelle as any).loadData();
    this.$bvModal.show("details-information-additionnelle");
  }

  goToCible(id: string, cible: string) {
    switch (cible) {
      case TypeEntiteEnum.UTILISATEUR:
        this.$router.push({ name: "edit-utilisateurs", params: { id: id } });
        break;
      case TypeEntiteEnum.ENTREPRISE:
        this.$router.push({ name: "details-adherent", params: { id: id } });
        break;
      case TypeEntiteEnum.FOURNISSEUR:
        this.$router.push({ name: "details-fournisseur", params: { id: id } });
        break;
      case TypeEntiteEnum.PARTENAIRE:
        this.$router.push({ name: "details-partenaires", params: { id: id } });
      default:
        break;
    }
  }

  async loadInformationsAdditionnelles(params: any) {
    if (!params || params?.reset) {
      this.listInformationsAdditionnelles =
        new PaginatedList<InformationAdditionnelle>();
    }
    await this.$http.myressif.informationsAdditionnelles
      .paginatedList(
        params?.TypeEntite, 
        params?.TypeEntiteId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10
      )
      .then((response: PaginatedList<InformationAdditionnelle>) => {
        this.listInformationsAdditionnelles = response;
      });
  }
}
