





























































import { Component, Vue } from "vue-property-decorator";
import ListInformationsAdditionnelles from "@/views/informationAdditionnelles/ListBasicInformationsAdditionnelles.vue";

@Component({
  components: {
    ListInformationsAdditionnelles,
  },
})
export default class Home extends Vue {
  // lastEntreprises: PaginatedList<EntrepriseShortStat> | null = null
  // async created() {
  //   await this.getLastEntreprises()
  // }
  // async getLastEntreprises(){
  //   await this.$http.myressif.entreprises.getLasts()
  //     .then((res: PaginatedList<EntrepriseShortStat>) => {
  //       this.lastEntreprises = res
  //     })
  // }
}
